import React from "react";
import Header from "./header/Header";
import './App.css';
import {BrowserRouter, useRoutes} from "react-router-dom";
import Home from "./home/Home";
import Dashboard from "./dashboard/Dashboard";
import SharingModule from "./sharing_module/SharingModule";
import SharingHistory from "./sharing_history/SharingHistory";
import SharingRequest from "./sharing_request/SharingRequest";
import SharingAction from "./sharing_action/SharingAction";

function App() {
    // const location = useLocation();
    // useEffect(() => {
    //     console.log('Current location is', location);
    // }, [location]);
    return useRoutes([
        {
            path: '/',
            element: <Home/>,
        },
        {
            path: '/dashboard',
            element: <Dashboard/>,
        },
        {
            path: '/dashboard/sharing_module',
            element: <SharingModule/>,
        },
        {
            path: '/dashboard/sharing_module/sharing_history',
            element: <SharingHistory/>,
        },
        {
            path: '/sharing_request',
            element: <SharingRequest/>,
        },
        {
            path: '/sharing_action',
            element: <SharingAction/>,
        }
    ]);
}

const AppWrapper = () => {
    return (
        <BrowserRouter>
            <Header />
            <App />
        </BrowserRouter>
    );
};

export default AppWrapper;